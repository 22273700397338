const LINK = {
  pinkfong:
    'https://www.pinkfong.com/?utm_source=thepinkfongcompany&utm_medium=web',
  'babyshark-tv': 'https://babyshark.tv',
  'pinkfong-store-en': 'https://www.pinkfong.com/en/?modal=opened',
  'pinkfong-store-ko':
    'https://store.pinkfong.com/?utm_source=thepinkfongcompany&utm_medium=web',
  facebook: 'https://www.facebook.com/thepinkfongcompany',
  linkedin: 'http://www.linkedin.com/company/thepinkfongcompany',
  'apply-workable': 'https://apply.workable.com/thepinkfongcompany',
  instagram: 'https://www.instagram.com/thepinkfongcompany',
  'pinkfong-plus-en':
    'https://www.pinkfong.com/pinkfong-plus/?utm_source=companyweb&utm_medium=biz_btn',
  'pinkfong-plus-ko':
    'https://www.pinkfong.com/pinkfong-plus/?utm_source=companyweb&utm_medium=biz_btn',
};

export default LINK;
